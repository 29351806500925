import './App.css';
import React, { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from './components/LoadingScreen';
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Users = lazy(() => import('./pages/Users'));
const Withdrawals = lazy(() => import('./pages/Withdrawals'));
const Deposits = lazy(() => import('./pages/Deposits'));
const AnnaBank = lazy(() => import('./pages/AnnaBank'));
const ChestParticipants = lazy(() => import('./pages/ChestParticipants'));
const FinancialAnalysis = lazy(() => import('./pages/FinancialAnalysis'));
const OpenChests = lazy(() => import('./pages/OpenChests'));
const RiskAnalysis = lazy(() => import('./pages/RiskAnalysis'));
const LoginContainer = lazy(() => import('./components/LoginContainer'));

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <AuthProvider>
      <ToastContainer />
      <Router>
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route path="/" element={<LoginContainer />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard setIsLoading={setIsLoading} isMenuOpen={isMenuOpen}/></PrivateRoute>} />
            <Route path="/users" element={<PrivateRoute><Users setIsLoading={setIsLoading} isMenuOpen={isMenuOpen}/></PrivateRoute>} />
            <Route path="/withdrawals" element={<PrivateRoute><Withdrawals setIsLoading={setIsLoading} isMenuOpen={isMenuOpen}/></PrivateRoute>} />
            <Route path="/deposits" element={<PrivateRoute><Deposits /></PrivateRoute>} />
            <Route path="/anna-bank" element={<PrivateRoute><AnnaBank  setIsLoading={setIsLoading} isMenuOpen={isMenuOpen}/></PrivateRoute>} />
            <Route path="/open-chests" element={<PrivateRoute><OpenChests setIsLoading={setIsLoading} isMenuOpen={isMenuOpen}/></PrivateRoute>} />
            <Route path="/chest-participants" element={<PrivateRoute><ChestParticipants setIsLoading={setIsLoading} isMenuOpen={isMenuOpen}/></PrivateRoute>} />
          </Routes>
        </Suspense>
      </Router>
    </AuthProvider>
  );
};

export default App;