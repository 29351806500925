import GroupIcon from "@mui/icons-material/Group";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InsightsIcon from '@mui/icons-material/Insights';
import HandymanIcon from '@mui/icons-material/Handyman';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';



const menuItems = [
  {
    title: "Dashboard",
    breadcrumb: "Dashboard",
    path: "/dashboard",
    icon: <InsightsIcon />,
  },
  {
    title: "Usuários",
    breadcrumb: "Usuários",
    path: "/users",
    icon: <GroupIcon />,
  },
  {
    title: "Saques",
    breadcrumb: "Saques",
    path: "/withdrawals",
    icon: <MoneyOffIcon />,
  },
  {
    title: "Anna Bank",
    breadcrumb: "Anna Bank",
    path: "/anna-bank",
    icon: <AccountBalanceIcon />,
  },
  /*{
    title: "Depósitos",
    breadcrumb: "Depósitos",
    path: "/deposits",
    icon: <AttachMoneyIcon />,
  },
  {
    title: "Análise de risco",
    breadcrumb: "Análise de risco",
    path: "/risk-analysis",
    icon: <WarningAmberIcon />,
  },
  {
    title: "Análise financeira",
    breadcrumb: "Análise financeira",
    path: "/financial-analysis",
    icon: <AdminPanelSettingsIcon />,
  },*/
  {
    title: "Baús abertos",
    breadcrumb: "Baús abertos",
    path: "/open-chests",
    icon: <EmojiEventsIcon />,
  },
  {
    title: "Baú participantes",
    breadcrumb: "Baú participantes",
    path: "/chest-participants",
    icon: <HandymanIcon />,
  }
];

export default menuItems;
