import React, { ReactNode, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LoadingScreen from "../components/LoadingScreen";
import Header from "./Header";
import Menu from "./Menu";
import { useMediaQuery } from "@mui/material";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  const [isMenuOpen, setIsMenuOpen] = useState(!isSmallScreen);
  const [isLoading, setIsLoading] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const setLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        backgroundColor: "#f5f5f5",
        position: "relative",
      }}
    >
      {isLoading && <LoadingScreen />}

      {/* Botão de abrir/fechar o menu */}
      <IconButton
        onClick={handleMenuToggle}
        sx={{
          position: "fixed",
          zIndex: 1202,
          top: 8,
          left: isMenuOpen ? 240 : 8,
          color: "primary.contrastText",
          transition: "left 0.3s",
        }}
      >
        {isMenuOpen ? <ChevronLeftIcon /> : <MenuIcon />}
      </IconButton>

      {/* Menu lateral */}
      <Box
        component="nav"
        sx={{
          width: 240,
          maxWidth: 240,
          display: isMenuOpen ? "block" : "none",
          height: "100vh",
          overflowY: "auto",
          position: "fixed",
          left: 0,
          top: 0,
          zIndex: 1201,
          backgroundColor: "#fff",
          transition: "width 0.3s",
          boxShadow: isMenuOpen ? "0 2px 5px rgba(0,0,0,0.2)" : "none",
        }}
      >
        <Menu />
      </Box>

      {/* Conteúdo principal */}
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: isMenuOpen ? "240px" : 0, // Ajusta a margem esquerda com base na largura do menu
          transition: "margin-left 0.3s",
          marginTop: "64px", // Espaço para o cabeçalho
          height: "calc(100vh - 64px)", // Ajuste para a altura do cabeçalho
          //display: "flex",
          //flexDirection: "column", // Alinha o conteúdo ao topo
          overflowY: "auto", // Permite rolagem vertical se necessário
          backgroundColor: "grey.200",
        }}
      >
        <Header isMenuOpen={isMenuOpen} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            backgroundColor: "grey.200",
            p: 2,
            display: "block", // Sobrescreve o display flex
            justifyContent: "flex-start", // Alinha ao topo
            alignItems: "flex-start", // Alinha ao topo
          }}
        >
          {React.cloneElement(children as React.ReactElement, { setLoading, isMenuOpen })}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
