import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Avatar, Divider, ListItemButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import menuItems from "./menuItens";
import { useAuth } from "./AuthContext";

const Menu: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [userAvatar, setUserAvatar] = useState<string | null>(null);
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const name = localStorage.getItem("userName");
    const avatar = localStorage.getItem("userAvatar");
    name && setUserName(name.split(" ")[0]);
    avatar && setUserAvatar(avatar);
  }, []);

  const handleClick = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" maxWidth="240px">
      <List component="nav">
        <ListItem sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: 1 }}>
            <Avatar sx={{ width: 58, height: 58 }} src={userAvatar ? userAvatar : ""} alt={userName ? userName : "Avatar"} />
          </Box>
          <Box>
            <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
              Olá,
            </Typography>
            <Typography variant="h5" fontWeight="bold" sx={{ fontSize: "1.1rem" }}>
              {userName}!
            </Typography>
          </Box>
        </ListItem>
        {menuItems.map((item, index) => (
          <div key={index}>
            <ListItemButton
              component={openIndex === index ? "div" : RouterLink}
              to={openIndex === index ? undefined : item.path}
              onClick={() => handleClick(index)}
              sx={{
                cursor: openIndex === index ? "default" : "pointer",
                "&:hover": {
                  backgroundColor: "rgba(18, 140, 126, 0.1)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  padding: "8px 12px",
                  borderRadius: "8px",
                  backgroundColor: openIndex === index ? "rgba(97, 193, 182, 0.15)" : "transparent",
                }}
              >
                <ListItemIcon sx={{ minWidth: 0, marginRight: 1, color: openIndex === index ? "#128C7E" : "inherit" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  sx={{
                    color: openIndex === index ? "#128C7E" : "inherit",
                    fontSize: "0.rem",
                  }}
                />
              </Box>
            </ListItemButton>
          </div>
        ))}
      </List>
      <Box flexGrow={1} />
      <List>
        <Divider />
        <ListItemButton
          onClick={handleLogout}
          sx={{
            "& .MuiListItemIcon-root": {
              minWidth: 0,
              marginRight: 1,
              fontSize: "0.9rem",
              color: "inherit",
            },
            "& .MuiListItemText-primary": {
              fontSize: "0.9rem",
              color: "inherit",
            },
            cursor: "pointer",
          }}
        >
          <ListItemIcon sx={{ fontSize: "0.9rem" }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" sx={{ fontSize: "0.9rem" }} />
        </ListItemButton>
      </List>
    </Box>
  );
};

export default Menu;
