import React, { createContext, useContext, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

type DecodedToken = {
  email: string;
  name: string;
  avatar: string;
};

interface AuthContextType {
  isAuthenticated: boolean;
  login: (token : string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  login: (token : string) => {},
  logout: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('authToken') ? true : false
  );

  const login = (token: string) => {
    localStorage.setItem('authToken', token);
  
    try {
      const decoded: DecodedToken = jwtDecode(token);
      localStorage.setItem('userEmail', decoded.email);
      localStorage.setItem('userName', decoded.name);
      localStorage.setItem('userAvatar', decoded.avatar);
    } catch (error) {
      console.error('Erro ao decodificar token:', error);
    }
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  // Opcional: Revalidar o token ao carregar a aplicação
  //useEffect(() => {
  //  const token = localStorage.getItem('authToken');
    // Aqui, você poderia adicionar uma verificação ao servidor para validar o token
  //  setIsAuthenticated(!!token);
  //}, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);