import React from "react";
import { AppBar, Toolbar, Box, useMediaQuery } from "@mui/material";

interface HeaderProps {
  isMenuOpen: boolean;
}

const Header: React.FC<HeaderProps> = ({ isMenuOpen }) => {
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  return (
    <AppBar position="fixed" sx={{ height: 60, backgroundColor: "#1f1f1f", zIndex: 1200 }}>
      <Toolbar sx={{ justifyContent: "center", alignItems: "center", paddingBottom: "1rem" }}>
        {((!isMenuOpen && isSmallScreen) || (!isSmallScreen)) && (<Box component="img" src="https://zlincontent.com/cdn/zlassets//betvencedor.com/logo.png?v=2" alt="logo bet vencedor" sx={{ mt: 2, maxWidth: 140 }} />)}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
